* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #262626;
}

.App {
  width: 90%;
  max-width: 1000px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

header {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

header h1 {
  color: #56BC58;
  font-size: 3.5rem;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

.form .form-control {
  margin: 2rem 0;
  width: 100%;
  max-width: 600px;
  position: relative;
}

.form .form-control input {
  font-size: 1.25rem;
  border: none;
  border-bottom: 2px solid #ffffff;
  background: none;
  text-align: center;
  padding-bottom: 1rem;
  caret-color: #56BC58;
  transition: border-bottom-color 0.2s ease-in-out;
  color: #ffffff;
  width: 100%;
}

.form .form-control input:focus {
  outline: none;
  border-bottom-color: #56BC58;
}

.form .form-control input:hover {
  border-bottom-color: #8aef87;
}

.form .form-control input::placeholder {
  color: #ffffff;
  opacity: 0.7;
}

.form .form-control .wave-label {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: -1;
  transform: translateX(-50%);
}

.form .form-control .wave-label span {
  display: inline-block;
  transition: transform 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  color: #e0e0e0;
  font-size: 1.25rem;
}

.form .form-control .wave-label span.space {
  width: 6px;
}

.form .form-control.move-placeholder .wave-label span {
  transform: translateY(-2rem);
}

.form button {
  font-size: 1.25rem;
  cursor: pointer;
  outline: none;
  border: none;
  box-shadow: 3px 3px 0 0 #ffffff;
  padding: 0.75rem 1.25rem;
  background-color: #56BC58;
  color: #262626;
  transition: box-shadow 0.2s ease-in-out;
  margin: 1rem;
  color: #ffffff;
  border-radius: 5px;
}

.form button:hover {
  box-shadow: 0 0 0 0 #ffffff;
}

.form button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
  box-shadow: none;
}

.form button i {
  margin-left: 1rem;
}

.checkbox-container {
  color: #ffffff;
  font-size: 1.25rem;
  margin: 1rem;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  display: flex;
  align-items: center;
}

.checkbox-container .label {
  opacity: 0.7;
}

.checkbox-container input {
  display: none
}

.checkbox-container label {
  height: 50px;
  width: 80px;
  background-color: #d0d0d0;
  display: inline-block;
  border-radius: 50px;
  margin-right: 1rem;
  position: relative;
  cursor: pointer;
  transition: background-color 0.2s linear;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.checkbox-container input:checked + label {
  background-color: #56BC58;
}

.checkbox-container label .circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0%, 5px);
  animation: slideOff 0.2s linear forwards;
}

.checkbox-container input:checked + label .circle {
  animation: slideOn 0.2s linear forwards;
}

@keyframes slideOn {
  from {
    transform: translate(5px, 5px);
  }

  to {
    transform: translate(calc(100% - 5px), 5px);
  }
}

@keyframes slideOff {
  from {
    transform: translate(calc(100% - 5px), 5px);
  }

  to {
    transform: translate(5px, 5px);
  }
}

.result, .error {
  margin: 1rem 0;
  display: flex;
  justify-content: center;
}

.result a {
  font-size: 1.25rem;
  color: #ffffff;
}

.error {
  font-size: 1.25rem;
  color: #d32f2f;
}

.url-table-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2em;
  justify-content: space-between;
  color: #ffffff;
  margin: 2rem 0;
}

@media(max-width: 700px) {
  .url-table-container {
    grid-template-columns: 1fr;
  }
}

.url-table {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.url-table h2, .url-table h3 {
  margin: 0.5rem 0;
}

.url-table i {
  margin: 2rem;
}

.url-table table {
  width: 100%;
  border-spacing: 0;
}

.url-table th, .url-table td {
  text-align: center;
  padding: 1rem 0;
}

.url-table td {
  cursor: pointer;
}

.url-table th {
  border-bottom: 2px solid #FFFFFF;
}

.url-table td {
  border-bottom: 1px solid #FFFFFF;
}

.url-table tr td {
  transition: border-bottom 0.2s ease-in-out;
}

.url-table tr:hover td {
  border-bottom: 1px solid #56BC58;
}

.url-table tr td:first-child {
  font-weight: bold;
}

/* Define an animation behavior */
@keyframes spinner {
  to { transform: rotate(360deg); }
}

/* This is the class name given by the Font Awesome component when icon contains 'spinner' */
.fa-spinner {
  /* Apply 'spinner' keyframes looping once every second (1s)  */
  animation: spinner 1.5s linear infinite;
}